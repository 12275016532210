import React, { FC, useState, useEffect } from 'react';
import { DrawerProps } from './Drawer.types';
import { StyledDrawerContainer, StyledDrawer, StyledDrawerButton } from './Drawer.styles';
import clsx from 'clsx';

export const Drawer: FC<DrawerProps> = props => {
  const { variant = 'drawer', initialState = false, placement, title, children, labels, onChange, dir = 'ltr' } = props;
  const isDrawer = variant === 'drawer';
  const ctaPositionMap = {
    left: 'top',
    bottom: 'right',
  };

  const [showDrawer, setShowDrawer] = useState(initialState);

  const drawerState = showDrawer ? 'open' : 'close';

  useEffect(() => {
    setShowDrawer(initialState);
  }, [initialState]);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    onChange?.(!showDrawer);
  };
  const getArrowDirection = (dir: string, showDrawer: boolean) => ((dir === 'ltr') === showDrawer ? 'left' : 'right');

  return (
    <StyledDrawerContainer
      className={isDrawer ? clsx(`drawer drawer-placement-${placement}`, drawerState) : 'drawer-container'}
      data-testid="drawer-container"
      data-component-name="m-groups-Drawer"
    >
      <StyledDrawer>
        {isDrawer && title && (
          <div className={clsx('drawer-title', showDrawer ? 'd-none' : 'd-block')} data-testid="drawer-title">
            {title}
          </div>
        )}
        <div
          className={clsx('drawer-content', showDrawer || !isDrawer ? 'd-block' : 'd-none')}
          data-testid="drawer-content"
        >
          {children}
        </div>
      </StyledDrawer>

      {isDrawer && (
        <div className="container d-flex justify-content-end">
          <StyledDrawerButton
            type="button"
            className={clsx(
              'drawer-icon-container',
              `button-placement-${ctaPositionMap[placement]}`,
              showDrawer ? 'open' : 'close'
            )}
            aria-label={`${showDrawer ? labels?.hideAriaLabel : labels?.showAriaLabel} ${labels?.drawerAriaLabel}`}
            onClick={toggleDrawer}
            data-testid="drawer-button"
          >
            <span className={clsx(`icon-arrow-${getArrowDirection(dir, showDrawer)}`, 'first')} />
            <span className={clsx(`icon-arrow-${getArrowDirection(dir, showDrawer)}`, 'second')} />
          </StyledDrawerButton>
        </div>
      )}
    </StyledDrawerContainer>
  );
};
