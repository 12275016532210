import moment from 'moment';
import {
  AddHotelResponse,
  Brand,
  HotelDetails,
  HotelDetailsInput,
  HotelMediaContent,
  MiniHotelResponse,
  PropertyAirport,
  ReslinkType,
  EventDetailsInput,
  RateAmount,
  RateDetail,
  RoomTypes,
} from '@marriott/mi-groups-graphql';
import { moveDecimalPoint } from './roomingList';
import { PropertyLabels } from '../organisms/reslink/ReslinkEventDetails/AddHotel/PropertyCard/PropertyCard.types';
import { ReslinkEventDetailsFormData } from '../organisms/reslink/ReslinkEventDetails/ReslinkEventDetails.types';
import { getPropertyAirportDistanceLabel, getPropertyItemValue, getReviewsUrl, getLocaleValue } from '../../utils';
import { ReservationLinkType } from '../organisms/reslink/DashboardContentTiles/ReslinkTypeModal/ReslinkTypeModal.types';
import { ARABIC, ARABIC_DOMAIN, DOMAIN_LOCALE_MAP, SPANISH, SPANISH_DOMAIN } from '../constants';

export const isGroupType = (reslinkType: ReservationLinkType) => reslinkType.toUpperCase() === ReservationLinkType.GRP;

export const isCorporateType = (reslinkType: ReservationLinkType) =>
  reslinkType.toUpperCase() === ReservationLinkType.CORP;

export const getPropertyData = (
  response: (AddHotelResponse & { miniHotelStartDate?: string }) | HotelDetails,
  propertyLabels: PropertyLabels
) => {
  const { property, miniHotelStartDate } = response;
  const { roomTypes, seoNickname, media, basicInformation, airports, reviews } = property;
  const { reviews: reviewsLabel, toAirport } = propertyLabels;

  const reviewsText = reviews?.numberOfReviews?.count
    ? `${getPropertyItemValue(reviews.numberOfReviews.count)} ${reviewsLabel}`
    : '';
  const reviewUrl = seoNickname ? getReviewsUrl(seoNickname, basicInformation?.brand?.id) : '#';
  const airportDistanceText = getPropertyAirportDistanceLabel(airports as PropertyAirport[], toAirport);
  const overview =
    basicInformation?.descriptions?.find(item => item.type.code === 'location')?.localizedText?.translatedText ||
    basicInformation?.descriptions?.[0]?.localizedText?.translatedText;

  const {
    isHousingProtected,
    bookByDate,
    fromDate,
    toDate,
    overriddenStartDate,
    overriddenEndDate,
    maxOccupancy,
    overriddenMaxOccupancy,
    rateRange,
    roomsData,
  } = (response as AddHotelResponse).searchMiniHotels
    ? getPartialPropertyDataOnAddHotel((response as AddHotelResponse).searchMiniHotels as MiniHotelResponse, roomTypes)
    : getPartialPropertyDataOnModifyReslink(response as HotelDetails);

  return {
    isHousingProtected: isHousingProtected as boolean,
    media: media as HotelMediaContent,
    brand: basicInformation?.brand as Brand,
    propertyId: property.id?.toString(),
    marshaCode: property.id?.toString()?.toUpperCase(),
    miniHotelCode: 'miniHotelId' in response ? response?.miniHotelId?.toUpperCase() : '',
    miniHotelStartDate,
    clusterCode: 'corporateCode' in response ? response.corporateCode : '',
    hotelName: basicInformation?.name || '',
    reviewsAndDistance: {
      rating: `${getPropertyItemValue(reviews?.stars?.count || '')}`,
      reviewCount: reviewsText,
      reviewLink: reviewUrl,
      distance: airportDistanceText,
    },
    description: overview || '',
    bookByDate,
    fromDate,
    toDate,
    overriddenStartDate,
    overriddenEndDate,
    maxOccupancy,
    overriddenMaxOccupancy,
    minRate: rateRange?.minRate,
    maxRate: rateRange?.maxRate,
    currency: rateRange?.currency,
    roomsData: roomsData || [],
  };
};

const getPartialPropertyDataOnAddHotel = (searchMiniHotels: MiniHotelResponse, roomTypes: RoomTypes) => {
  const { node: miniHotel } = searchMiniHotels?.edges?.[0] || {};
  const { isHousingProtected, basicInformation, startDate: fromDate, endDate: toDate, rates } = miniHotel || {};
  const bookByDate = basicInformation?.cutoffDate;

  const maxOccupancy = getMaxOccupancy(rates || []);
  const rateRange = getRateRange(rates || []);
  const roomTypeCodes = roomTypes.edges.map(roomType => roomType.node.roomTypeCode);

  const roomsData = rates?.map(rate => {
    const { rateValue, currency } = getBaseRate(rate.rateAmounts);
    const matchingRoomTypeCode = roomTypeCodes.find(roomTypeCode => rate.roomType === roomTypeCode);

    return {
      fromDate: rate.startDate,
      toDate: rate.endDate,
      maxOccupancy: matchingRoomTypeCode ? rate.roomTypeDetail?.maxOccupancy : undefined,
      groupCode: rate.groupCode as string,
      roomType: matchingRoomTypeCode ? rate.roomTypeDetail?.name : undefined,
      roomRate: rateValue,
      currency,
      isRemoved: false,
    };
  });

  return {
    isHousingProtected: !!isHousingProtected,
    bookByDate,
    fromDate,
    toDate,
    overriddenStartDate: '',
    overriddenEndDate: '',
    maxOccupancy,
    overriddenMaxOccupancy: undefined,
    rateRange,
    roomsData,
  };
};

const getPartialPropertyDataOnModifyReslink = (hotelDetails: HotelDetails) => {
  const {
    groupCodes,
    maxOccupancy: hotelMaxOccupancy,
    overriddenStartDate,
    overriddenEndDate,
    miniHotelInfo,
    miniHotelStartDate,
  } = hotelDetails;
  const {
    isHousingProtected,
    basicInformation,
    startDate: fromDate,
    endDate: toDate,
    rates,
  } = miniHotelInfo?.[0]?.node || {};
  const bookByDate = basicInformation?.cutoffDate;

  const filteredRates = getFilteredRates(groupCodes, rates);
  const maxOccupancy = +hotelMaxOccupancy || getMaxOccupancy(filteredRates);
  const overriddenMaxOccupancy = +hotelMaxOccupancy || undefined;
  const rateRange = getRateRange(filteredRates);

  const roomsData = rates?.map(rate => {
    const { rateValue, currency } = getBaseRate(rate.rateAmounts);
    return {
      fromDate: rate.startDate,
      toDate: rate.endDate,
      maxOccupancy: rate?.roomTypeDetail?.maxOccupancy,
      roomType: rate?.roomTypeDetail?.name,
      groupCode: rate.groupCode as string,
      roomRate: rateValue,
      currency: currency,
      isRemoved: !groupCodes.includes(rate.groupCode as string),
    };
  });

  return {
    isHousingProtected: !!isHousingProtected,
    miniHotelStartDate,
    bookByDate,
    fromDate,
    toDate,
    overriddenStartDate,
    overriddenEndDate,
    maxOccupancy,
    overriddenMaxOccupancy,
    rateRange,
    roomsData,
  };
};

export const getFilteredRates = (groupCodes: string[], rates?: RateDetail[]) => {
  return rates?.filter(({ groupCode }) => groupCodes?.includes(groupCode as string)) || [];
};

const getMaxOccupancy = (rates: RateDetail[]) => {
  return rates.reduce((maxGuest: number | undefined, roomType) => {
    return maxGuest
      ? Math.max(maxGuest, roomType?.roomTypeDetail?.maxOccupancy || 0)
      : roomType?.roomTypeDetail?.maxOccupancy;
  }, undefined);
};

export const getRateRange = (rates: RateDetail[]) => {
  return rates.reduce(
    (acc: { minRate: string | number; maxRate: string | number; currency: string }, rate) => {
      const { rateValue, currency } = getBaseRate(rate.rateAmounts);
      if (rateValue !== '-') {
        acc.minRate = acc.minRate === '-' ? rateValue : Math.min(acc.minRate as number, rateValue as number);
        acc.maxRate = acc.maxRate === '-' ? rateValue : Math.max(acc.maxRate as number, rateValue as number);
      }
      acc.currency = acc.currency || currency;
      return acc;
    },
    { minRate: '-', maxRate: '-', currency: '' }
  );
};

export const getBaseRate = (rateAmounts: RateAmount[]) => {
  const { amount } = rateAmounts.find(({ rateMode }) => rateMode?.code === 'base-rate') || {};
  const rateValue = moveDecimalPoint(amount?.value as number, amount?.valueDecimalPoint as number);
  return { rateValue, currency: amount?.currency || '' };
};

export const getCreateReslinkEventInput = (isGroup: boolean, data: ReslinkEventDetailsFormData, id: string) => {
  const {
    domain,
    associates,
    eventName: name,
    customImages,
    eventDescription: description,
    eventLink1,
    eventLinkName1,
    eventLink2,
    eventLinkName2,
    propertyCards,
  } = data;

  const eventLinks = [];
  eventLink1 && eventLinks.push({ link: eventLink1, name: eventLinkName1 });
  eventLink2 && eventLinks.push({ link: eventLink2, name: eventLinkName2 });

  const minStartDate: moment.Moment[] = [];
  const maxEndDate: moment.Moment[] = [];
  const hotelDetails: HotelDetailsInput[] = [];

  propertyCards.forEach(
    ({
      propertyId,
      miniHotelCode = '',
      miniHotelStartDate,
      clusterCode = null,
      fromDate = '',
      toDate = '',
      overriddenStartDate = '',
      overriddenEndDate = '',
      overriddenMaxOccupancy,
      roomsData = [],
    }) => {
      if (isGroup) {
        minStartDate.push(moment(overriddenStartDate || fromDate));
        maxEndDate.push(moment(overriddenEndDate || toDate));

        hotelDetails.push({
          propertyId,
          clusterCode,
          groupCodes: roomsData.filter(room => !room.isRemoved).map(room => room.groupCode),
          miniHotelId: miniHotelCode,
          miniHotelStartDate,
          overriddenStartDate,
          overriddenEndDate,
          overriddenMaxOccupancy,
        });
      } else {
        hotelDetails.push({
          propertyId,
          miniHotelStartDate: '',
          clusterCode,
          groupCodes: [],
        });
      }
    }
  );

  const eventDetails: EventDetailsInput = {
    localeURL: domain.value,
    associatedUsers: associates.split(/[;,]/g),
    name,
    customImageURLs: customImages,
    description,
    eventLinks,
    ...(isGroup
      ? {
          startDate: moment.min(minStartDate).format(getLocaleValue('hyphenatedDateWithMonthNoAndYear')),
          endDate: moment.max(maxEndDate).format(getLocaleValue('hyphenatedDateWithMonthNoAndYear')),
        }
      : {}),
  };

  if (id) {
    eventDetails.id = id;
  }

  return {
    reslinkType: isGroup ? ReslinkType.GRP : ReslinkType.CORP,
    eventDetail: eventDetails,
    hotelDetails,
  };
};

export const getlocalefromDomain = (url: string) => {
  if (url.toLowerCase().includes(SPANISH)) {
    return DOMAIN_LOCALE_MAP[SPANISH_DOMAIN];
  } else if (url.toLowerCase().includes(ARABIC)) {
    return DOMAIN_LOCALE_MAP[ARABIC_DOMAIN];
  } else {
    const matchingDomain = Object.keys(DOMAIN_LOCALE_MAP).find(domain =>
      url.toLowerCase().endsWith(domain.toLowerCase())
    );
    return DOMAIN_LOCALE_MAP[matchingDomain as string] ?? '';
  }
};
